@use '../../../../styles/variables' as *;

.theme-wrapper {
  --panelFontHighlight: $main-blue;
  --panelFont: $panelFont;
  --panelBg: $panelBg;
  --panelAccent: $panelAccent;
  --fontFamily: 'Source Sans Pro', sans-serif;
  --panelText: $panelText;
  --panelTextHover: $panelTextHover;
  --primary50: $primary50;
  --primary75: $primary75;
  --primary100: $primary100;
  --primary200: $primary200;
  --primary300: $primary300;
  --primary400: $primary400;
  --primary500: $primary500;
  --primary600: $primary600;
  --primary700: $primary700;
  --primary800: $primary800;
  --primary900: $primary900;
}

$variables: (
  --panelFontHighlight: var(--panelFontHighlight),
  --panelFont: var(--panelFont),
  --panelBg: var(--panelBg),
  --panelAccent: var(--panelAccent),
  --fontFamily: var(--fontFamily),
  --panelText: var(--panelText),
  --panelTextHover: var(--panelTextHover),
  --primary50: var(--primary50),
  --primary75: var(--primary75),
  --primary100: var(--primary100),
  --primary200: var(--primary200),
  --primary300: var(--primary300),
  --primary400: var(--primary400),
  --primary500: var(--primary500),
  --primary600: var(--primary600),
  --primary700: var(--primary700),
  --primary800: var(--primary800),
  --primary900: var(--primary900),
);

@function var($variable) {
  @return map-get($variables, $variable);
}

/* //
// HEADER
//  */

/* // Logo and soyburger icon container */
.sidePanelLogo {
  background-color: var(--panelBg) !important;
}

/* // Background of logo and soyburger icon */
.sidePanelLogo__bg {
  // opacity: 1;
  background-color: var(--panelAccent) !important;
}

/* // Header */
.topBar {
  background-color: var(--panelAccent) !important;
}

/* // Invite user button, User dropdown button | top right corner */
.topBar__action {
  color: var(--panelFont) !important;
}

/* //
// SIDEBAR
//  */

/* // Sidebar main container */
.filterPanel {
  color: var(--panelFont) !important;
  background-color: var(--panelBg) !important;
}

/* // Separator between main navigation and site navigation */
.mainNav__border-bottom {
  /* border-bottom-color: rgba(110, 120, 129, 0.5); */
}

/* // Main navigation - INACTIVE navigation item */

/* // Main navigation - ACTIVE navigation item */
.mainNav__element--active {
  background-color: var(--panelFontHighlight) !important;
}

/* // Site navigation - Account container */
.sidePanel__right-header,
.folderNav__element,
.sidePanel__search,
.sidePanel__search-input,
.mainNav__option,
.siteNav__element-new-site,
.topBar__site-name,
.topBar__account-name,
.siteNav__element-empty-folder,
.siteNav__drag-icon,
.sidePanel__search-close,
.accountNav__element {
  /* // Text color of inactive accounts/sites */
  color: var(--panelText) !important;
  border-color: var(--panelText) !important;
}

.top__setting-icon {
  opacity: 0.6;
  color: var(--panelText) !important;

  &:hover {
    opacity: 1;
  }
}

.top__setting-icon--active {
  color: var(--panelFontHighlight) !important;
}

.sidePanel__search {
  input::placeholder {
    color: var(--panelText) !important;
  }
}

.customSvg {
  svg {
    color: var(--panelText) !important;
  }
}

/* // Site navigation - Account circle icon */
.folderNav__element .account__circle-icon,
.accountNav__element .account__circle-icon {
  color: var(--panelFont) !important;
}

.site-nav__element {
  color: var(--panelText) !important;
}

.site-nav__element:hover {
  background-color: var(--panelTextHover) !important;

  .mainNav__options {
    opacity: 1 !important;
  }
}
