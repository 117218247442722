@mixin phone-and-down {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin mobile-and-down {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin tablet-and-up {
  @media (min-width: 901px) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: 901px) and (max-width: 1124px) {
    @content;
  }
}

@mixin tablet-and-down {
  @media (max-width: 1124px) {
    @content;
  }
}

//============================//
//Topbar dimensions for widths

@mixin s-screen-and-down {
  @media (max-width: 660px) {
    @content;
  }
}

@mixin s-screen-and-up {
  @media (min-width: 661px) {
    @content;
  }
}

@mixin m-screen-and-down {
  @media (max-width: 850px) {
    @content;
  }
}

@mixin l-screen-and-up {
  @media (min-width: 851px) {
    @content;
  }
}

@mixin l-screen-only {
  @media (min-width: 851px) and (max-width: 1000px) {
    @content;
  }
}

@mixin xl-screen {
  @media (max-width: 1100px) {
    @content;
  }
}

@mixin xl-screen-and-up {
  @media (min-width: 1100px) {
    @content;
  }
}

@mixin xxl-screen {
  @media (max-width: 1170px) {
    @content;
  }
}

@mixin xxl-screen-and-up {
  @media (min-width: 1170px) {
    @content;
  }
}

@mixin xxl-screen-and-down {
  @media (max-width: 1170px) {
    @content;
  }
}

//============================//

// This is because the settings table was a width of 1200px this breakpoint is being used to change the width of the table contents when the screen size is less than 1300px
@mixin table-breakpoint {
  @media (max-width: 1300px) {
    @content;
  }
}

@mixin desktop-and-up {
  @media (min-width: 1125px) {
    @content;
  }
}

// mobile
.hide--mobile {
  @include mobile-and-down {
    display: none !important;
  }
}

// tablet
.hide--tablet-down {
  @include tablet-and-down {
    display: none !important;
  }
}

.hide--tablet-up {
  @include tablet-and-up {
    display: none !important;
  }
}

// desktop
.hide--desktop {
  @include desktop-and-up {
    display: none !important;
  }
}

// f) Retina Breakpoints(@2x)
// @media(-webkit-min-device-pixel-ratio:1.5),(min--moz-device-pixel-ratio:1.5),(-o-min-device-pixel-ratio:3/2),(min-resolution:1.5dppx){

// }
